<template>
    <v-container fluid>
        <v-card outlined>
            <v-card-title class="primary--text">{{$t('erp.newStock')}}</v-card-title>
            <v-divider/>
            <v-card-text>
                <v-form lazy-validation ref="form">
                    <v-row>
                        <v-col class="ma-0 pb-0" cols="12" sm="6">
                            <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL" :rules="[
                                v => !!v
                            ]" @focus="showTouchKeyboard"

                                          :label="$t('erp.lang_inventoryName')"
                                          outlined
                                          v-model="data.stockTakingName"

                            />
                        </v-col>

                        <v-col class="ma-0 pb-0" cols="12" sm="6">
                            <v-select :items="this.storage" :label="$t('generic.lang_formSelectMakeASelection')"
                                      :loading="this.loadingSelect"
                                      :rules="[
                                v => !!v
                            ]"
                                      item-text="storageName"
                                      item-value="storageID"
                                      outlined
                                      v-model="data.stockTakingForStorageID"
                            />

                        </v-col>

                        <v-col class="ma-0 pt-0" cols="12" md="6">
                            <v-switch class="ma-0 pt-"
                                      :label="$t('erp.includeSalesMadeDuringStockTaking')"
                                      v-model="data.reCalcFromSellings"
                            />
                        </v-col>

                        <v-col class="ma-0 pt-0" cols="12" md="6">
                            <v-switch class="ma-0 pt-"
                                      :label="$t('erp.setUnscannedStockTakingItemsZero')"
                                      v-model="data.setStockValueZeroForNonScannedItems"
                            />
                        </v-col>

                        <v-col class="mx-auto pt-0" cols="12" md="6">
                            <v-switch class="ma-0 pt-"
                                      :label="$t('erp.stockTakingUseMobileCamera')"
                                      v-model="data.useCameraAsBarcodeScanner"
                            />
                        </v-col>

                        <v-col cols="12" md="12">
                            <v-btn :disabled="loading" :loading="this.loading" @click="create" block color="primary"
                                   large>
                                {{$t('generic.lang_create')}}
                            </v-btn>
                        </v-col>

                        <v-col>

                            <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
                                <vue-touch-keyboard :accept="hideTouchKeyboard" :cancel="hideTouchKeyboard"
                                                    :defaultKeySet="touchKeyboard.keySet"
                                                    :input="touchKeyboard.input" :layout="touchKeyboard.layout"
                                                    :options="touchKeyboard.options" class="internalWidthExpanded"
                                                    id="onScreenKeyboard" v-if="touchKeyboard.visible"/>
                            </div>
                        </v-col>

                    </v-row>
                </v-form>

            </v-card-text>

        </v-card>
    </v-container>
</template>

<script>

    //configs
    import {ENDPOINTS} from "../../../../config";
    import mixin from "../../../../mixins/KeyboardMixIns";
    import {Events} from "../../../../plugins/events";


    export default {
        name: "StockTakingCreateComponent",
        mixins: [mixin],
        data: () => {
            return {
                storage: [],
                ENDPOINT: ENDPOINTS,
                data: {
                    stockTakingName: "",
                    stockTakingForStorageID: "",
                    reCalcFromSellings: false,
                    setStockValueZeroForNonScannedItems: false,
                    useCameraAsBarcodeScanner: false
                },
                loadingSelect: false,
                loading: false
            }
        },
        watch: {},
        methods: {
            loadStorages() {
                this.loadingSelect = true;
                this.axios.get(this.ENDPOINT.DATATABLES.ERP.STORAGEOVERVIEW).then((res) => {
                    const dt = res.data.aaData;


                    //filter data
                    dt.forEach((elt) => {
                        this.storage.push({
                            storageID: elt[0],
                            storageName: elt[1],
                        })
                    });

                    this.loadingSelect = false;
                }).catch((err) => {
                    this.loadingSelect = false;
                    Events.$emit("showSnackbar", {
                        color: "error",
                        message: err.message
                    });
                });
            },
            create() {
                if (!this.$refs.form.validate()) return;
                this.loading = true;

                this.axios.post(this.ENDPOINT.ERP.STOCKTAKING.CREATE, this.data).then((res) => {
                    Events.$emit("showSnackbar", {
                        color: "success",
                        message: this.$t('erp.stockTakingCreated')
                    });
                    this.$refs.form.reset();
                    this.loading = false;
                    
                    let timer = setTimeout(() => {
                        this.$router.replace('/erp/storage/stockTaking/' + res.data.stockTakingID + '/create');
                        clearTimeout(timer)
                    }, 1000)
                }).catch((err) => {
                    this.loading = false;
                    Events.$emit("showSnackbar", {
                        color: "error",
                        message: err.message
                    });
                });
            }
        },
        mounted() {
            this.loadStorages();
        },
    }
</script>

